import React from 'react'

const ArticleHtml = ({ input }) => {
  return input?.primary?.html
    ? (
      <div className="small-container flex justify-center mx-auto my-6">
        <div className="w-full flex overflow-hidden" dangerouslySetInnerHTML={{ __html: input.primary.html }} />
      </div>
    ) : null
}

export default ArticleHtml
export { ArticleHtml }
import React from 'react'
import { RichText } from 'prismic-reactjs'

const ArticleContent = ({ input, htmlSerializer }) => {
	return (
		<div
			className="small-container px-6"
		>
      <RichText
        htmlSerializer={htmlSerializer}
        render={input.primary.rich_text.raw}
      />
    </div>
	)
}

export default ArticleContent

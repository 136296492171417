import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ArticleImage = ({ input }) => {
	const {
		primary: { image_size, the_image, caption },
	} = input,
    image = getImage(the_image)

  let className = `w-full`,
    container = ``,
    modifier = ``,
    sizes = ``

  switch(image_size) {
    case `Small`:
      container = `max-w-495 px-6`
      modifier = `w-3/4`
      sizes = `(min-width: 495px) calc((495px - 48px) * 3 / 4), calc((100vw - 48px) * 3 / 4)`
      break
    case `Medium`:
      container = `max-w-495 px-6`
      sizes = `(min-width: 495px) calc(495px - 48px), calc(100vw - 48px)`
      break
    case `Large`:
      container = `max-w-650 mx-auto md:px-6`
      sizes = `(min-width: 768px) calc(650px - 48px), (min-width: 650px) 650px, 100vw`
      break
    case `Extra Large`:
      container = `w-screen`
      sizes = `100vw`
      break
    default:
      break
  }

	let altStyles

	if (image_size !== 'Extra Large') {
		altStyles = `lg:absolute lg:top-0 lg:-right-2.5 lg:w-auto lg:transform lg:translate-x-full lg:max-w-250`
	} else {
		altStyles = `lg:w-495 mt-2.5`
	}

	return image?.images ? (
		<div className={`${container} mx-auto relative`}>
			<div className={`${modifier} my-8 mx-auto relative`}>
        <GatsbyImage
          alt={image?.alt ?? ``}
          image={image}
          className={className}
          layout="fullWidth"
          sizes={sizes}
        />
				{caption?.text && (
					<p
						className={`w-9/12 mx-auto font-sans text-xxs ${altStyles}`}
					>
						{caption.text}
					</p>
				)}
			</div>
		</div>
	) : null
}

export default ArticleImage

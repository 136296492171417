import React from 'react'
import PropTypes from 'prop-types'
import { ArticleContent, ArticleImage, ArticleHtml } from '../slices'
import { Elements } from 'prismic-reactjs';
import propsWithUniqueKey from '../utils/propsWithUniqueKey'
import replaceNonBreakingSpace from '../utils/replaceNonBreakingSpace'
import arrayOrOnlyItem from '../utils/arrayOrOnlyItem'

const SliceZone = ({ allSlices }) => {
  // Create an htmlSerializer with reference and footnote counts reset to zero
  const htmlSerializer = (() => {
    let referenceCount = 0,
    footnoteCount = 0
  
    return (type, element, content, children, key) => {
      const childrenOrChild = arrayOrOnlyItem(children)

      // Return HTML for an piece of content.
      switch(type) {
        case Elements.paragraph:
          // Null
          if(childrenOrChild === null || /^\s*$/.test(childrenOrChild)) return React.createElement(React.Fragment, propsWithUniqueKey({}, key))

          // Null child
          if(
            Array.isArray(children)
              && children.length === 1
              && /^\s*$/.test(children[0]?.props?.children)
          ) return React.createElement(React.Fragment, propsWithUniqueKey({}, key))
          
          // Only asterisk
          if(/^\*$/.test(childrenOrChild)) return React.createElement(`p`, propsWithUniqueKey({ className: `text-center` }, key), `*`)
    
          // Only footnote
          if(
            Array.isArray(children)
              && children.length === 1
              && children[0]?.props?.[`data-footnote`]
          ) return React.createElement(`p`, propsWithUniqueKey({ "data-only-footnote": true }, key), replaceNonBreakingSpace(content, children))
    
          // Default
          return React.createElement(`p`, propsWithUniqueKey({}, key), replaceNonBreakingSpace(content, children))
    
        case Elements.heading2:
          return React.createElement(`h2`, propsWithUniqueKey({}, key), replaceNonBreakingSpace(content, children))
    
        case Elements.heading4:
          return React.createElement(`h4`, propsWithUniqueKey({}, key), replaceNonBreakingSpace(content, children))
    
        case Elements.label:
          switch(element.data.label) {
            case `reference`:
              referenceCount++
              return React.createElement(`span`, propsWithUniqueKey({
                "data-reference": true,
                id: `reference-${referenceCount}`,
                href: `#footnote-${referenceCount}`,
                tabIndex: 0,
              }, key), replaceNonBreakingSpace(content, children))
            case `footnote`:
              footnoteCount++
              return React.createElement(`span`, propsWithUniqueKey({
                "data-footnote": true,
                id: `footnote-${footnoteCount}`,
                href: `#reference-${footnoteCount}`,
                tabIndex: 0,
              }, key), replaceNonBreakingSpace(content, children))
            default:
              return React.createElement(`span`, propsWithUniqueKey({
                className: element.data.label
              }, key), replaceNonBreakingSpace(content, children))
          }

          case Elements.strong:
            return React.createElement(`strong`, propsWithUniqueKey({}, key), replaceNonBreakingSpace(content, children))

          case Elements.em:
            return React.createElement(`em`, propsWithUniqueKey({}, key), replaceNonBreakingSpace(content, children))

      }
      return null
    }
  })()

	return allSlices.map(slice => {
		switch (slice.slice_type) {
			// These are the API IDs of the slices
			case 'content':
				return <ArticleContent key={slice.id} input={slice} htmlSerializer={htmlSerializer} />
			case 'image':
				return <ArticleImage key={slice.id} input={slice} />
      case 'html':
        return <ArticleHtml key={slice.id} input={slice} />
			default:
				return null
		}
	})
}

SliceZone.propTypes = {
	allSlices: PropTypes.array.isRequired,
}

export default SliceZone
